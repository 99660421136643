import Cache from 'common/cache/lib/utils/Cache';
import { DBSERVICES_PER_PAGE, ITEMS_PER_PAGE } from './appConstants';
import { ENV_VARIABLES, ENV } from './env';
import {
  ALERTS_BASE,
  AUDIT_LOGS_BASE,
  AVAILABILITY_MACHINES_BASE,
  DB_BACKUP_BASE,
  DB_SERVICES_BASE,
  DOWNLOADS_BASE,
  EXCEPTIONS_BASE,
  GENIE_BASE,
  IAM_BASE,
  INTERNAL_BASE,
  TENANTS_BASE,
  TICKETS_BASE,
} from './URLConstants';

const BASE_URL = ENV_VARIABLES[ENV].BASE_URL;
const BASE_PROXY_URL = ENV_VARIABLES[ENV].BASE_PROXY_URL;

//Sections
export const GROUPS_BASE_URL = `${BASE_URL}${IAM_BASE}/groups`;
export const DB_SERVICES_BASE_URL = `${BASE_URL}${DB_SERVICES_BASE}`;
export const USERS_BASE_URL = `${BASE_URL}${IAM_BASE}/users`;
export const TENANTS_BASE_URL = `${BASE_URL}${TENANTS_BASE}`;
export const AUDIT_LOGS_BASE_URL = `${BASE_URL}${AUDIT_LOGS_BASE}`;
export const TICKETS_BASE_URL = `${BASE_URL}${TICKETS_BASE}`;
export const TICKETS_INTERNAL_BASE_URL = `${TICKETS_BASE_URL}/${INTERNAL_BASE}`;
export const GENIE_BASE_PROXY_URL = `${BASE_PROXY_URL}${GENIE_BASE}`;
export const ALERTS_BASE_URL = `${BASE_URL}${ALERTS_BASE}`;
export const BACKUP_BASE_URL = `${BASE_URL}${DB_BACKUP_BASE}`;
export const EXCEPTIONS_BASE_URL = `${BASE_URL}${EXCEPTIONS_BASE}`;
export const AVAILABILITY_MACHINES_BASE_URL = `${BASE_URL}${AVAILABILITY_MACHINES_BASE}`;
export const DOWNLOADS_BASE_URL = `${BASE_URL}${DOWNLOADS_BASE}`;
export const CREDITS_BASE_URL = `${BASE_PROXY_URL}credits?`;
export const COST_BASE_URL = `${BASE_URL}cost`;

export const URLS = {
  //Computes
  getAllComputes: `${BASE_URL}computes`,
  getComputeDetails: `${BASE_URL}compute-resources/`,

  // Tenants
  getTenantIdNames: `${TENANTS_BASE_URL}/domains?`,
  getTenantRegions: `${TENANTS_BASE_URL}/regions`,

  // DB services
  fetchDBsURL: `${DB_SERVICES_BASE_URL}?page-size=${DBSERVICES_PER_PAGE}&page-offset=`,
  fetchUsersByTenantIdURL: `${BASE_URL}users?tenant-id={0}`,
  fetchCloudAccountDetails: `${BASE_URL}cloud-accounts/`,
  fetchInfraDeployementDetails: `${BASE_URL}infra-deployments/`,
  fetchInfraResourceDetails: `${BASE_URL}infra-resources?cloud-resource-url=`,
  fetchAvailabilityMachineDaps: `${BASE_PROXY_URL}dap?`,
  fetchMonitoringDetails: `${BASE_PROXY_URL}monitor`,
  fetchTOPProcess: `${BASE_PROXY_URL}monitor/top-processes?`,
  fetchEvents: `${BASE_PROXY_URL}notifications`,
  PITR: `${BASE_PROXY_URL}pitr?tenant-domain=`,
  fetchConnectionPools: `${BASE_PROXY_URL}connection-pools?`,
  fetchDBIntegrations: `${BASE_PROXY_URL}integrations?`,
  fetchStartStop: `${BASE_PROXY_URL}${DB_SERVICES_BASE}/`,

  // DB logs
  fetchDBLogs: `${BASE_PROXY_URL}dblogs?`,
  fetchLogFiles: `${BASE_PROXY_URL}logfiles?`,

  //Availability Machines
  fetchAMs: `${AVAILABILITY_MACHINES_BASE_URL}?`,
  fetchAmsCount: `${AVAILABILITY_MACHINES_BASE_URL}/count?`,

  //Clones
  fetchClonesByAvailabilityMachineId: `${DB_SERVICES_BASE_URL}?`,
  fetchClonesCount: `${DB_SERVICES_BASE_URL}/count?`,

  // VM logs
  fetchVMLogs: `${BASE_PROXY_URL}VMlogs?`,
  fetchVMLogFiles: `${BASE_PROXY_URL}VMlogfiles?`,

  // Update metadata
  updateDbServicesMetadata: `${BASE_PROXY_URL}dbservices?`,
  updateDatabaseMetadata: `${BASE_PROXY_URL}databases?`,
  updateBackupsMetadata: `${BASE_PROXY_URL}backups?`,
  updateInstancesMetadata: `${BASE_PROXY_URL}service-instances?`,
  updateComputeResourcesMetadata: `${BASE_PROXY_URL}compute-resources?`,
  updateTenantMetadata: `${BASE_PROXY_URL}tenant?`,
  updateTenantGenieConfig: `${BASE_PROXY_URL}genie/genieConfig?`,

  // Get counts
  fetchTenantsCount: `${TENANTS_BASE_URL}/count`,
  fetchServicesCount: `${DB_SERVICES_BASE_URL}/count`,
  fetchUsersCount: `${BASE_URL}users/count`,

  // Tickets
  fetchTicketsByTenantIdURL: `${TICKETS_BASE_URL}?tenant-ids={}`,
  fetchTicketsSummaryByTenantId: `${TICKETS_BASE_URL}/summary?tenant-ids={}`,
  fetchTicketsCountByTenantId: `${TICKETS_BASE_URL}/count?tenant-ids={}`,
  fetchTicketAgents: `${TICKETS_BASE_URL}/agents`,
  replyToTicket: `${TICKETS_BASE_URL}/reply`,

  getJiraComponents: `${TICKETS_INTERNAL_BASE_URL}/components`,
  getJiraAssignableUsers: `${TICKETS_INTERNAL_BASE_URL}/assignable-users`,
  getJiraIssueTypes: `${TICKETS_INTERNAL_BASE_URL}/issue-types`,
  getJiraReasons: `${TICKETS_INTERNAL_BASE_URL}/reasons`,

  // Genie
  requestGenie: `${GENIE_BASE_PROXY_URL}/requestAccess`,
  enableGenie: `${GENIE_BASE_PROXY_URL}/enableGenie`,
  disableGenie: `${GENIE_BASE_PROXY_URL}/disableGenie`,
  fetchGenieStatus: `${GENIE_BASE_PROXY_URL}/status?`,
  resendGenieOtp: `${GENIE_BASE_PROXY_URL}/resendOTP?`,
  downloadGenieConfig: `${GENIE_BASE_PROXY_URL}/download?`,
  extendGenie: `${GENIE_BASE_PROXY_URL}/extendGenie?`,

  //Forgot Password
  forgotPassword: `${BASE_PROXY_URL}forgot-password`,

  // Alerts
  getAlertSilences: `${ALERTS_BASE_URL}/silenced`,
  silenceAlertsURL: `${BASE_PROXY_URL}silence`,
  expireSilence: `${BASE_PROXY_URL}expireSilence?id=`,
  dBServiceByComputeIdURL: `${BASE_URL}compute-resources/`,
  bulkResolveAlertsURL: `${ALERTS_BASE_URL}/bulkResolve`,
  bulkAssignAlertsURL: `${ALERTS_BASE_URL}/bulkAssign`,

  // Audit logs
  fetchAuditLogs: `${AUDIT_LOGS_BASE_URL}?page-size=${ITEMS_PER_PAGE}&page-offset=`,
  fetchAuditLogsCount: `${AUDIT_LOGS_BASE_URL}/count`,
  fetchAuditLogsUsers: `${AUDIT_LOGS_BASE_URL}/users`,
  fetchAuditLogsTenants: `${TENANTS_BASE_URL}/domains`,
  fetchAuditLogsTypes: `${AUDIT_LOGS_BASE_URL}/types`,
  downloadAuditLogs: `${AUDIT_LOGS_BASE_URL}/download-report`,
  fetchAuditLogsReasons: `${BASE_URL}tickets/internal/reasons-map`,

  // dashboard
  dashboardSummaryURL: `${BASE_URL}dashboard`,

  // VPC
  fetchVPCPeeringsURL: `${BASE_PROXY_URL}vpc-peerings?`,
  fetchVPCDetailsURL: `${BASE_PROXY_URL}vpc?`,
  updateSubnetsMetadata: `${BASE_PROXY_URL}subnet?`,

  // Exception
  fetchExceptionsCount: `${EXCEPTIONS_BASE_URL}/count?`,
  fetchExceptionTypes: `${EXCEPTIONS_BASE_URL}/types`,
  fetchTenantsListLeaks: `${EXCEPTIONS_BASE_URL}/tenant-list?is-leak=true`,

  // Toggle Billing
  toggleBilling: `${BASE_PROXY_URL}billing?`,

  // PG Sync
  refreshPGSync: `${BASE_PROXY_URL}pgSyncMonitoring`,
  fetchPGSyncMonitoring: `${BASE_URL}dashboard/pgsync-monitoring`,

  // Export
  fetchDownloadsCount: `${DOWNLOADS_BASE_URL}/count?`,
  downloadExportedFile: `${DOWNLOADS_BASE_URL}/data?file-name=`,
  exportDBServices: `${DB_SERVICES_BASE_URL}/export?`,
  exportTenants: `${TENANTS_BASE_URL}/export?`,

  //Onboarding
  onboard: `${BASE_PROXY_URL}onboard`,
  getDeploymentNames: `${BASE_PROXY_URL}onboard/deployment?deployment-plan=`,
  getAvailableNamespaces: `${BASE_PROXY_URL}onboard/namespace?infra-deployment-id=`,

  //Infra Monitoring
  fetchTenantDomains: `${TENANTS_BASE_URL}/pg-source`,
  fetchSpecificContainerMetrics: `${BASE_PROXY_URL}monitoring-infra/container-metrics?`,
  fetchAllContainerMetrics: `${BASE_PROXY_URL}monitoring-infra/container-metrics/summary?`,

  //DB Servers
  fetchComputes: `${BASE_URL}compute-resources?`,
  fetchComputesCount: `${BASE_URL}compute-resources/count?`,

  //OTP
  otp: `${BASE_URL}otp?`,

  //Permissions
  getUserPermissions: `${USERS_BASE_URL}/permissions`,
  getAllPermissions: `${BASE_URL}${IAM_BASE}/permissions`,

  //DB Services Summary
  getMyRole: `${BASE_PROXY_URL}dbservices?`,
  getParameterProfile: `${BASE_PROXY_URL}${DB_SERVICES_BASE}/parameter-profiles/`,

  //AVailability Machine Details
  getSLATiming: `${BASE_PROXY_URL}sla-schedule?`,

  //Cloud Instance Details
  getCloudInstanceDetails: `${BASE_PROXY_URL}${DB_SERVICES_BASE}/`,

  //Events
  getServiceEvents: `${BASE_PROXY_URL}db-services/`,

  //DB Engine Config
  getDBEngineConfig: `${BASE_PROXY_URL}engine-config?`,
};

export const headers = () => {
  return {
    Authorization: 'Bearer ' + Cache.get('idToken'),
  };
};
